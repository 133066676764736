import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Header from "components/Header"
import Footer from "components/Footer"
import ProductCard from "components/ProductCard"
import Heading from "components/Heading"
import Filters from 'components/Filters'
import { priceSortOptions, priceFilterOptions } from "config/store-filters"
import { distinctProductTypesArray } from "utils/distinct-array"

import './product-archive.css'

const defaultFilters = {
    priceSort: priceSortOptions[0],
}

function ProductArchiveTemplate ({ pageContext, data }) {
    const [ filters, setFilters ] = React.useState( defaultFilters )
    const [ currentPage, setCurrentPage ]= React.useState(1)
    const { allShopifyProduct, productTypes } = data;

    const setfilterHoop = ( e ) => {
        const res = e();
        if(  Object.keys(filters).length > 0){ 
            setFilters( { ...filters, ...res } ) 
        } else {
            setFilters ( res )
        }
    }

    let products = allShopifyProduct.edges;
    if( filters.colours && filters.colours.length > 0 ){
        products = products.filter( product => {
            const colourIndex = product.node.options.find( opt => opt.name === "Colour")
            if( ! colourIndex ){
                return false;
            }
            const found = colourIndex.values.some( selectedColour => filters.colours.includes(selectedColour))
            return found
        } )
    }

    if( filters.priceFilter && filters.priceFilter.length > 0) {
        const priceStrings = filters.priceFilter.flatMap( filter => filter.split(" - "))
        const priceValues = priceStrings.map( str => Number(str.replace('£',''))).sort( (a,b) => a-b )
        const min = priceValues.shift();
        const max = priceValues.pop();
        products = products.filter( product => {
            const price = product.node.priceRange.minVariantPrice.amount;
            return price >= min && price <= max
        } )
    }

    if( filters.priceSort ) {
        products.sort( (a,b) => {
            const aP = Number(a.node.priceRange.minVariantPrice.amount);
            const bP = Number(b.node.priceRange.minVariantPrice.amount);
            if( priceSortOptions[0] === filters.priceSort ){
                return aP - bP
            }
            return bP - aP
        })
    }

    const limit = pageContext.limit
    const numPages = Math.ceil(products.length / limit);
    const prev = currentPage > 1 ? currentPage - 1 : null
    const next = currentPage < numPages ? currentPage + 1 : null
    const skip = (currentPage - 1) * limit
    const lastItemIndex =  (skip + limit < products.length) ? skip + limit : products.length
    products = products.slice(skip, lastItemIndex)

    const pageNavigation = thisPage => {
        var links = [];
        const active = {
            borderRadius: "4px",
            backgroundColor: "var(--seasonal-color)",
            color: "var(--white)"
        }

        const inactiveArrow = {
            opacity: `0.3`,
            cursor: `default`
        }

        if (prev !== null) {
            links.push(
                <div
                    onClick={() => setCurrentPage(prev)}
                    key={0}
                    className="linkArrow"
                >
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.16802 12.5L7.5 11.09L3.17341 6.5L7.5 1.91L6.16802 0.5L0.500001 6.5L6.16802 12.5Z" fill="#2F3542"/>
                    </svg>
                </div>
            );
        } else {
            links.push(
                <div
                    style={inactiveArrow}
                    key={0}
                    className="linkArrow"
                >
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.16802 12.5L7.5 11.09L3.17341 6.5L7.5 1.91L6.16802 0.5L0.500001 6.5L6.16802 12.5Z" fill="#2F3542"/>
                    </svg>
                </div>
            );
        }
        // divider between arrow and numbers
        links.push(
            <svg key={`1st-divider`} width="2" height="35" viewBox="0 0 2 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="2.18557e-08" x2="0.999998" y2="35" stroke="#747D8C"/>
            </svg>
        )
        for (let i = 1; i < numPages + 1; i++) {
            links.push(
                <div
                    onClick={() => setCurrentPage(i)}
                    key={i}
                    style={thisPage === i ? active : null}
                    className="linkNumber"
                >
                    {i}
                </div>
            );
        }
        // 2nd divider
        links.push(
            <svg key={`2nd-divider`} width="2" height="35" viewBox="0 0 2 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="2.18557e-08" x2="0.999998" y2="35" stroke="#747D8C"/>
            </svg>
        )
        if (next !== null) {
            links.push(
                <div
                    onClick={() => setCurrentPage(next)}
                    key={numPages + 1}
                    className="linkArrow"
                >
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.83198 12.5L0.5 11.09L4.82659 6.5L0.499999 1.91L1.83198 0.5L7.5 6.5L1.83198 12.5Z" fill="#2F3542"/>
                    </svg>
                </div>
            );
        } else {
            links.push(
                <div
                    style={inactiveArrow}
                    key={numPages + 1}
                    className="linkArrow"
                >
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.83198 12.5L0.5 11.09L4.82659 6.5L0.499999 1.91L1.83198 0.5L7.5 6.5L1.83198 12.5Z" fill="#2F3542"/>
                    </svg>
                </div>
            );
        }
        return <nav className="paginationNav">{links}</nav>;
    }

    const productsJSX = products.map( ({ node })  => {
        let numColors = 1
        node.options.forEach(option => {
            if (option.name === "Colour") {
                numColors = option.values.length
            }
            
        });
        return (
            <div key={ node.id } className="productCardContainer">
                <ProductCard product={node} colors={numColors} hoverEffect={ true }/>
            </div> )
    });

    // Grab all product options and filter to only include colours, then return a set of unique colours
    const colours = ( () => {
        const options = allShopifyProduct.edges.flatMap( ({node}) => node.options );
        const colourOptions = options.filter( opt => opt.name === "Colour" );
        const colours = colourOptions.flatMap( colour => colour.values )
        return [ ...new Set( colours ) ]
    })();
    const productTypesDistinct = distinctProductTypesArray(productTypes);

    let categoriesJSX = productTypesDistinct.map( item => 
        <Link
            key={ item }
            to={`/products/${item.toLowerCase()}`}
            className={ `productCategory ${item === pageContext.productType ? " productCategory__active" : ""}` }
        >
            <Heading level="h5">{item}</Heading>
        </Link>
    );
    

    return (
        <main>
            <Header />
            <section>
                <div className="options">
                    <div className="productCategories">
                        { categoriesJSX }
                    </div>
    
                    <div className="filters">
                        <Filters 
                          priceSort ={ priceSortOptions }
                          priceFilter ={ priceFilterOptions}
                          colours= {colours}
                          filters = {filters}
                          setFilters={ setfilterHoop }
                        />
                    </div>
                </div>
                
                {products.length > 0 ?
                <div className="productsContainer">
                    { productsJSX }
                </div>
                :
                <div className="noMatch">
                    <Heading level="h5">No products matching this criteria</Heading>
                </div>
                
                }
                {numPages > 1 ? pageNavigation(currentPage) : <div style={{height: `100px`}}/>}
                
            </section>
            <Footer />
        </main>
    )
}

export const query = graphql`
query ProductArchiveQuery {
    productTypes: allShopifyProduct {
        edges{
            node{
                productType
            }
        }
    }
    allShopifyProduct {
        edges {
            node {
                options {
                    values
                    name
                }
                priceRange {
                    maxVariantPrice {
                        amount
                    }
                    minVariantPrice {
                        amount
                    }
                }
                handle
                title
                description
                handle
                images {
                    localFile {
                        url
                    }
                }
                variants {
                    price
                }
            }
        }
    }
}
`

export default ProductArchiveTemplate;
